import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT, API_PARADISE } from "src/constants/minutApi"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import {
  IParadiseHome,
  IParadiseHomeLogFilter,
  IParadiseHomeLogResponse,
  IParadiseHomesResponse,
  IParadiseTransferHomeToNewOrgBody,
  IParadiseUpdateHomeTagsBody,
  IParadiseUpdateHomeTagsResponse,
  TParadiseHomesListFilter,
  TParadisePostCsvHomesError,
  TParadisePostCsvHomesResponse,
} from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

import {
  paradiseHomesKeys,
  useParadiseHomesCache,
} from "./paradiseHomeQueryCache"

export function useFetchParadiseHomes<
  F extends TParadiseHomesListFilter,
  ResponseType = F["user_id"] extends string // This check is made due to the API only returning "homes" when filtering on "user_id"
    ? Pick<IParadiseHomesResponse, "homes" | "total_count">
    : IParadiseHomesResponse,
>({
  filter,
  options,
}: {
  // Make sure the caller only pass in known properties of TParadiseHomesListFilter
  filter?: {
    [K in keyof F]: K extends keyof TParadiseHomesListFilter ? F[K] : never
  }
  options?: UseQueryOptions<
    ResponseType,
    AxiosError,
    ResponseType,
    ReturnType<typeof paradiseHomesKeys.homeList>
  >
}) {
  async function fetchParadiseHomes() {
    const response = await minutApiHttpClient.get(
      `${API_PARADISE}/homes`,
      filter && {
        params: {
          limit: filter.limit ?? 50,
          offset: filter.offset ?? 0,
          user_id: filter.user_id,
          "filter[name]": filter.name,
          "filter[tags]": filter.tag,
          "filter[home_id]": filter.home_id,
          "filter[deleted]": filter.deleted,
          ...(filter?.sort && {
            [`sort[${filter.sort}]`]: filter.sort_by ?? "desc",
          }),
        },
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseHomesKeys.homeList(filter),
    queryFn: fetchParadiseHomes,
    ...options,
  })
}

export function useFetchParadiseHome({
  homeId,
  options,
}: {
  homeId: string
  options?: UseQueryOptions<
    IParadiseHome,
    AxiosError,
    IParadiseHome,
    ReturnType<typeof paradiseHomesKeys.home>
  >
}) {
  async function getParadiseHome() {
    const response = await minutApiHttpClient.get<IParadiseHome>(
      `${API_PARADISE}/homes/${homeId}`
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseHomesKeys.home(homeId),
    queryFn: getParadiseHome,
    ...options,
  })
}

export function useFetchParadiseHomeLog({
  homeId,
  filter,
  options,
}: {
  homeId: string
  filter?: IParadiseHomeLogFilter
  options?: UseQueryOptions<
    IParadiseHomeLogResponse,
    AxiosError,
    IParadiseHomeLogResponse,
    ReturnType<typeof paradiseHomesKeys.homeLog>
  >
}) {
  async function getParadiseHomeLog() {
    const response = await minutApiHttpClient.get<IParadiseHomeLogResponse>(
      `${API_PARADISE}/homes/${homeId}/home_log`,
      {
        params: filter,
      }
    )

    return response.data
  }

  return useQuery({
    queryKey: paradiseHomesKeys.homeLog(homeId, filter),
    queryFn: getParadiseHomeLog,
    ...options,
  })
}

export function usePutTransferHomeToNewOrg() {
  const paradiseHomecache = useParadiseHomesCache()

  async function putTransferHomeToNewOrg(
    body: IParadiseTransferHomeToNewOrgBody
  ) {
    const response = await minutApiHttpClient.put(
      `${API_PARADISE}/run-script`,
      {
        script_name: "transfer_home_to_new_org",
        dry_run: false,
        ...body,
      }
    )

    return response.data
  }

  return useMutation({
    mutationFn: putTransferHomeToNewOrg,
    onSuccess: async (data, vars) => {
      await paradiseHomecache.invalidateParadiseHomeList()

      return Promise.all(
        vars.homes.map((h) => paradiseHomecache.invalidateParadiseHome(h))
      )
    },
  })
}

export function usePutRestoreHome() {
  const paradiseHomecache = useParadiseHomesCache()

  async function putRestoreHome(homeId: string) {
    const response = await minutApiHttpClient.put(
      `${API_PARADISE}/homes/${homeId}/undelete`,
      {} // This endpoint doesn't require a body
    )
    return response.data
  }

  return useMutation<IParadiseHome, AxiosError<{ message: string }>, string>({
    mutationFn: putRestoreHome,
    onSuccess: async (data) => {
      await paradiseHomecache.invalidateParadiseHomeList()

      paradiseHomecache.invalidateParadiseHome(data.home_id)
    },
  })
}

export function usePutHomeTags() {
  const paradiseHomecache = useParadiseHomesCache()

  async function putHomeTags({
    homeId,
    body,
  }: {
    homeId: string
    body: IParadiseUpdateHomeTagsBody
  }) {
    const response =
      await minutApiHttpClient.put<IParadiseUpdateHomeTagsResponse>(
        `${API_PARADISE}/homes/${homeId}/tags`,
        body
      )

    return response.data
  }

  return useMutation({
    mutationFn: putHomeTags,
    onSuccess: async (data) => {
      return Promise.all([
        paradiseHomecache.invalidateParadiseHomeList(),
        paradiseHomecache.invalidateParadiseHome(data.home_id),
      ])
    },
  })
}

// ts-prune-ignore-next
export function usePostCsvHomes() {
  const paradiseHomecache = useParadiseHomesCache()
  const cache = useQueryClient()

  async function postCsvHomes({
    orgId,
    body,
  }: {
    orgId: string
    body: string
  }) {
    const response =
      await minutApiHttpClient.post<TParadisePostCsvHomesResponse>(
        `${API_DEFAULT}/organizations/${orgId}/csv/homes`,
        body,
        {
          headers: {
            "Content-Type": "text/csv",
          },
        }
      )

    return response.data
  }

  return useMutation<
    TParadisePostCsvHomesResponse,
    AxiosError<TParadisePostCsvHomesError>,
    {
      orgId: string
      body: string
    }
  >({
    mutationFn: postCsvHomes,
    onSuccess: (data, vars) => {
      return Promise.all([
        paradiseHomecache.invalidateParadiseHomeList(),
        cache.invalidateQueries(orgsKeys.homeLists(vars.orgId)),
      ])
    },
  })
}
