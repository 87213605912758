import { useQueryClient } from "@tanstack/react-query"

import {
  IParadiseHomeLogFilter,
  TParadiseHomesListFilter,
} from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"

export const paradiseHomesKeys = {
  all() {
    return ["paradiseHome"] as const
  },

  homeLists() {
    return [...this.all(), "list"] as const
  },

  homeList(filter?: TParadiseHomesListFilter) {
    if (!filter) {
      return [...this.homeLists()] as const
    }

    return [...this.homeLists(), filter] as const
  },

  home(homeId: string) {
    return [...this.all(), homeId] as const
  },

  homeLog(homeId: string, filter?: IParadiseHomeLogFilter) {
    if (!filter) {
      return [...this.home(homeId), "log"] as const
    }

    return [...this.home(homeId), "log", filter] as const
  },
} as const

export function useParadiseHomesCache() {
  const queryClient = useQueryClient()

  function invalidateParadiseHomeList(filter?: TParadiseHomesListFilter) {
    const cacheKey = paradiseHomesKeys.homeList(filter)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  function invalidateParadiseHome(homeId: string) {
    const cacheKey = paradiseHomesKeys.home(homeId)

    return queryClient.invalidateQueries({
      queryKey: cacheKey,
    })
  }

  return {
    invalidateParadiseHome,
    invalidateParadiseHomeList,
  }
}
